import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable } from 'rxjs';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/fr';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  local_storage_prefixe = "marketplace.angular";
  taf_base_url = "https://marketbaraka.com/taf/";
  files_base_url = "https://marketbaraka.com/images/";
  network: any = {
    token: undefined,
    status: true,
    message: "Aucun probléme détecté",
  }
  token: any = {
    token_key: null,
    token_decoded: null,
    user_connected: null,
    is_expired: null,
    date_expiration: null
  }

  id_privilege: any
  utilisateur_connecte: any = {}
  full_menu: any[] = [
    {
      menu_header: "Commerciale",
      items: [
        // {
        //   text: "Tableau de bord",
        //   path: "/home/dashboard",
        //   icone: "bxs-dashboard",
        //   privileges: [1, 2]
        // },
        {
          text: "Commandes",
          path: "/home/commande",
          icone: "bx bxs-spreadsheet",
          privileges: [1, 2]
        },
        {
          text: "Produits",
          path: "/home/produit",
          icone: "bx bxs-data",
          privileges: [1, 2]
        },
        {
          text: "Catégories",
          path: "/home/categorie",
          icone: "bx bxs-customize",
          privileges: [1]
        },
        // {
        //   text: "Promotion",
        //   path: "/home/promotion",
        //   icone: "bx bxl-redux",
        //   privileges: [1, 2]
        // },
      ]
    },
    {
      menu_header: "Parametrage",
      items: [
        {
          text: "Vendeurs",
          path: "/home/utilisateur",
          icone: "bx bxs-user",
          privileges: [1]
        },
        {
          text: "Abonnements",
          path: "/home/type_abonnement",
          icone: "bx bx-credit-card-alt",
          privileges: [1]
        },
        {
          text: "Mon profil",
          path: "/home/profil",
          icone: "bx bx-user",
          privileges: [1,2,3]
        },
      ]
    }
  ]
  public nombreProduitPanier = JSON.parse(localStorage.getItem('panier') ?? '[]').length;

  menu: any[] = []
  les_produits: any[] = []
  constructor(private http: HttpClient, private route: Router) { }
  // sauvegardes
  get_from_local_storage(key: string): any {
    let res: any = localStorage.getItem(this.local_storage_prefixe + key);
    return JSON.parse(res)
  }
  save_on_local_storage(key: string, value: any) {
    localStorage.setItem(this.local_storage_prefixe + key, JSON.stringify(value));
    this.isUserConnected
  }
  delete_from_local_storage(key: string) {
    localStorage.setItem(this.local_storage_prefixe + key, 'null');
    this.isUserConnected
    // console.log("isUserConnected", this.isUserConnected)
  }
  get isUserConnected(): boolean {
    let token: any = localStorage.getItem(this.local_storage_prefixe + "token");
    // console.log("token",JSON.parse(token))
    return JSON.parse(token) != null;
  }
  get_token() {
    //le token n'est pas encore chargé
    if (this.network.token == undefined) {
      this.network.token = this.get_from_local_storage("token")
      if (this.network.token != undefined && this.network.token != null) {// token existant
        this.update_data_from_token()// mise a jour du token
      }
    } else {// token dèja chargé
      this.update_data_from_token()// mise a jour du token
    }
    return this.network.token
  }
  //les requetes http
  async taf_get(path: string, on_success: Function, on_error: Function) {
    let api_url = this.taf_base_url + path;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.get_token(),
      })
    };

    this.http.get(api_url, httpOptions).subscribe(
      (reponse: any) => {// on success
        on_success(reponse)
      },
      (error: any) => {// on error
        this.on_taf_get_error(error, on_error)
      }
    )
  }
  on_taf_get_error(error: any, on_error: Function) {
    this.network.status = false;
    this.network.message = error
    alert("Merci de vérifier votre connexion")
    on_error(error)
  }
  // async taf_post(path: string, data_to_send: any, on_success: Function, on_error: Function) {
  //   let api_url = this.taf_base_url + path;
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       Authorization: "Bearer " + this.get_token(),
  //     })
  //   };
  //   this.http.post(api_url, data_to_send, httpOptions).subscribe(
  //     (reponse: any) => {// on success
  //       on_success(reponse)
  //     },
  //     (error: any) => {// on error
  //       this.on_taf_post_error(error, on_error)
  //     }
  //   )
  // }
  async taf_post(path: string, data_to_send: any, on_success: Function, on_error: Function, offline: boolean = false) {
    // toujours chercher les données en local avant de demander au backend
    if (offline) {
      let segments = path.split("/")
      let dernier = segments[segments.length - 1]
      if (dernier.includes("get")) {// reception
        let reponse_offline = await this.get_from_local_storage(path)
        if (reponse_offline == null) {
          reponse_offline = {
            status: true,
            data: []
          }
        }
        console.log("on est pas en ligne et reponse_offline= ", reponse_offline)
        on_success(reponse_offline)
      }
    }

    let api_url = this.taf_base_url + path;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + await this.get_token(),
      })
    };
    this.http.post(api_url, data_to_send, httpOptions).subscribe(
      (reponse: any) => {// on success
        on_success(reponse)
        if (offline) {
          this.save_on_local_storage(path, reponse)
        }
      },
      (error: any) => {// on error
        this.on_taf_post_error(error, on_error)
      }
    )
  }
  async taf_post_login(path: string, data_to_send: any, on_success: Function, on_error: Function) {
    let api_url = this.taf_base_url + path;

    this.http.post(api_url, data_to_send).subscribe(
      (reponse: any) => {// on success
        on_success(reponse)
      },
      (error: any) => {// on error
        this.on_taf_post_error(error, on_error)
      }
    )
  }
  on_taf_post_error(error: any, on_error: any) {
    this.network.status = false;
    this.network.message = error
    alert("Merci de vérifier votre connexion")
    on_error(error)
  }
  update_data_from_token() {
    let token_key = this.get_from_local_storage("token")
    if (!token_key) {
      return
    }
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token_key);
    const expirationDate = helper.getTokenExpirationDate(token_key);
    const isExpired = helper.isTokenExpired(token_key);

    this.token = {
      token_key: token_key,
      token_decoded: decodedToken,
      user_connected: decodedToken.taf_data,
      is_expired: isExpired,
      date_expiration: expirationDate
    }
    if (this.token.is_expired) {
      this.on_token_expire()
    }
  }
  on_token_expire() {
    alert("Votre session s'est expiré! Veuillez vous connecter à nouveau")
    this.delete_from_local_storage("token")
    this.route.navigate(['/public/accueil'])
  }
  custom_menu() {
    this.id_privilege = parseInt(this.token.token_decoded.taf_data.id_role) //2
    console.log("id_privilege= ", this.id_privilege)
    // filtrer les items auquels ce privilege a accés
    console.log("menu= ", this.menu, " full_menu= ", this.full_menu)
    this.menu = this.full_menu.filter((un_menu: any) => {
      let item_allowed = un_menu.items.filter((un_item: any) => { return un_item.privileges.indexOf(this.id_privilege) != -1 })
      return item_allowed.length > 0
    }).map((un_menu: any) => {
      let item_allowed = un_menu.items.filter((un_item: any) => { return un_item.privileges.indexOf(this.id_privilege) != -1 })
      return {
        menu_header: un_menu.menu_header,
        items: item_allowed
      }
    })
  }
  async taf_post_with_files(path: string, data_to_send: any, form_files: any, on_success: Function, on_error: Function) {
    let api_url = this.taf_base_url + path;
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + await this.get_token(),
      })
    };
    const formData = new FormData();
    // Ajouter les données du formulaire au FormData
    for (const key in data_to_send) {
      formData.append(key, data_to_send[key]);
    }
    // Ajouter les fichiers sélectionné au FormData
    for (const key in form_files) {
      for (let i = 0; i < form_files[key].length; i++) {
        const one_file = form_files[key][i];
        formData.append(key + "[]", one_file);
      }
    }
    this.http.post(api_url, formData, httpOptions).subscribe(
      (reponse: any) => {// on success
        on_success(reponse)
      },
      (error: any) => {// on error
        this.on_taf_post_error(error, on_error)
      }
    )
  }
  postToPanier(produit: any, quantite = 0) {
    console.log("produit", produit)
    if (produit.quantite == 0) {
      this.Swal_info("Ce produit est en rupture de stock")
    } else {

      let produitPanier = {};
      if (localStorage.getItem('panier') == null || localStorage.getItem('panier') == undefined) {
        if (quantite != 0) {

          produitPanier = {
            produit: produit,
            quantitePanier: quantite
          }
        } else {
          produitPanier = {
            produit: produit,
            quantitePanier: 1
          }
        }
        localStorage.setItem('panier', JSON.stringify([produitPanier]));
        this.Swal_success("Produit ajouté au panier");
      } else {
        let panier = JSON.parse(localStorage.getItem('panier') ?? '[]');
        let a = panier.filter((item: any) => item.produit.id_produit == produit.id_produit);
        // console.log(a.length);
        if (a.length > 0) {

          this.Swal_info("Ce produit existe déja dans le panier");
        } else {
          if (quantite != 0) {

            produitPanier = {
              produit: produit,
              quantitePanier: quantite
            }
          } else {

            produitPanier = {
              produit: produit,
              quantitePanier: 1
            }
          }
          // let produitPanier = {
          //   produit: produit,
          //   quantitePanier: 1
          // }
          panier.push(produitPanier);
          localStorage.setItem('panier', JSON.stringify(panier));
          this.Swal_success("Produit ajouté au panier");
        }
      }
      // cardContent = JSON.parse(localStorage.getItem('panier') ?? '[]').length;
      this.nombreProduitPanier = JSON.parse(localStorage.getItem('panier') ?? '[]').length;
    }
  }

  getFromPanier() {
    return JSON.parse(localStorage.getItem('panier') ?? '[]');
  }

  clearPanier() {
    this.nombreProduitPanier = ""
    localStorage.removeItem('panier');
  }


  Swal_success(title: any) {
    let succes = Swal.fire({
      title: title,
      icon: "success",
      timer: 1000
    });
    return succes
  }

  Swal_error(title: any) {
    let error = Swal.fire({
      title: title,
      icon: "error",
      timer: 1000
    });
    return error
  }

  Swal_info(title: any) {
    let info = Swal.fire({
      title: title,
      icon: "info",
      timer: 1000
    });
    return info
  }

  async Swal_confirm() {
    const result = await Swal.fire({
      title: "Etes-vous sûr de vouloir supprimer",
      text: "C'est irréversible",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non"
    });
    return result.isConfirmed;
  }
  private refresh: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  public getRefresh(): Observable<any> {
    return this.refresh.asObservable();
  }

  public setRefresh(value: any): void {
    this.refresh.next(value);
  }
  format_date(date_string: string) {
    return {
      full: moment(date_string).locale("fr").format("dddd Do MMMM YYYY"),// 27 février 2023 
      jma: moment(date_string).locale("fr").format("Do MMMM YYYY"),// jeudi ...
      jma2: moment(date_string).locale("fr").format("DD-MM-YYYY"),// 01-11-2023
      jma4: moment(date_string).locale("fr").format("DD-MM-YYYY HH:MM"),// 01-11-2023
      jma3: moment(date_string).locale("fr").format("YYYY-MM-DD"),// 2023-10-21
      full_datetime: moment(date_string).locale("fr").format("dddd Do MMMM YYYY à HH:mm"),// 27 février 2023 
    }
  }
  format_current_date() {
    return {
      full: moment().locale("fr").format("dddd Do MMMM YYYY"),// 27 février 2023 
      jma: moment().locale("fr").format("Do MMMM YYYY"),// jeudi ...
      jma2: moment().locale("fr").format("DD-MM-YYYY"),// 01-11-2023
      full_datetime: moment().locale("fr").format("dddd Do MMMM YYYY à HH:mm"),// 27 février 2023 
    }
  }
}